import React from "react";
import { ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import TGFullScreenError from "../../../../../shared/tg-full-screen-error";
import { airAwardPaymentInitReset } from "./../../../slice/airAwardPaymentInitSlice";

const RedemptionError: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRetry = () => {
    dispatch(airAwardPaymentInitReset())
    const timeStamp: any = new Date() - new Date(sessionStorage?.getItem("timestamp"))
    if((timeStamp/1000)/60 < 10){
      navigate(`/${i18n?.language}/redemption/review`);
    }
    else {
      navigate(`/${i18n?.language}/`);
    }
  };

  return (
    <>
      <TGFullScreenError onClickPrimary={handleRetry} onClickSec={()=>{}} />
    </>
  );
};

export default RedemptionError;
