import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import styles from "./redemption-success.module.scss";
import TGIcon from "../../../../../shared/tg-icon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import ticketRestriction from "../../../../../../public/json/ticketRestriction.json";
import { camelCase } from "../../../../../utils/helper";
import Redemption from "../index";
import { airportDataRequest } from "../../../slice/bookingWidgetSlice";
import { AirportData } from "../../../models/models";
import { constant } from "../../../../../config/constant";
import { airAwardConfirmationRequest } from "../../../../../OSCI/src/slice/airAwardConfirmationSlice";
import TGFullScreenError from "../../../../../shared/tg-full-screen-error";
import { airAwardPaymentInitReset } from "../../../../../OSCI/src/slice/airAwardPaymentInitSlice";

const RedemptionSuccess: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { review_info_member } = constant;
  const dispatch = useDispatch();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const airportData: AirportData | null = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );
  const summaryRes = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardConfirmation
  );
  const summaryResponse = summaryRes?.data?.data;
  const confirmationRes = summaryResponse?.pnrSummaryResponseDto;
  console.log("confirmationRes", summaryRes);
  useEffect(() => {
    dispatch(airportDataRequest());
    const raw = {
      tid: window.location.search?.split("&")?.[1]?.split("=")[1],
      bookingRefId: window.location.search?.split("&")?.[2]?.split("=")[1],
    };
    //   const raw = {
    //     "bookingRefId": "4183",
    //     "tid": "1512"
    // }
    dispatch(airAwardConfirmationRequest(raw));
  }, []);

  // the below api response is getting from master code api
  const renderFlightDetails = (airportCode: any) => {
    const airportCodeVal = airportData?.filter(
      (data: any) => data?.airportCode === airportCode
    )?.[0];
    return airportCodeVal;
  };

  const formatDate = (str: string) => {
    let n = 2;
    let insertChar = "-";
    let outputString =
      str.slice(2, 4) +
      insertChar +
      str.slice(0, 2) +
      insertChar +
      str.slice(4);
    return outputString;
  };

  const printSuccessReceipt = () => {
    let printContents = document?.getElementById("receiptToPrint").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const displayNameInfo = () => {
    const userName: any = sessionStorage.getItem("FullName");
    const name = userName && userName.split(" ");
    const displayName = name && name[1] ? name[1] : "Olivia";
    const lastName = userName
      ? userName?.split(" ")[userName?.split(" ").length - 1]
      : "Brown";
    return (
      displayName?.charAt(0).toUpperCase() +
      displayName?.slice(1).toLowerCase() +
      " " +
      lastName?.charAt(0).toUpperCase() +
      lastName?.slice(1).toLowerCase()
    );
  };

  const ticketRestrictionData = (
    ticketRestriction: { ticketRestriction: string }[]
  ) => {
    const nestedItems = ticketRestriction?.map((nestedItem: any) => (
      <li>{nestedItem?.ticketRestriction}</li>
    ));
    return <ul>{nestedItems}</ul>;
  };

  const handleRetry = () => {
    dispatch(airAwardPaymentInitReset());
    const timeStamp: any =
      new Date() - new Date(sessionStorage?.getItem("timestamp"));
    if (timeStamp / 1000 / 60 < 10) {
      navigate(`/${i18n?.language}/redemption/review`);
    } else {
      navigate(`/${i18n?.language}/`);
    }
  };

  return summaryResponse?.paymentStatus === "Success" &&
    !summaryRes?.isLoading ? (
    <>
      <Redemption />
      <div
        className={`${styles.redemptionSuccessContainer} global-content-padding`}
      >
        <div className={styles.redemptionSuccessInfo}>
          <div className={styles.redemptionSuccessInfoDiv}>
            <span className={styles.redemptionSuccessInfoLabel}>
              <span>
                <TGIcon
                  icon="green-check-icon"
                  size={isDesktopView ? "48px" : "32px"}
                  fillColor=""
                />
              </span>
              <span>Redemption Completed</span>
            </span>
            <span className={styles.redemptionSuccessDescWrapper}>
              <div className={styles.redemptionSuccessDescIcon}>
                <TGIcon icon="green-check-icon" size="24px" fillColor="" />
              </div>
              <div className={styles.redemptionSuccessDescLabel}>
                <div className={styles.redemptionSuccessHeading}>
                  E-Ticket issued
                </div>
                <div className={styles.redemptionSuccessDesc}>
                  An e-ticket has been sent to your email. You can also view
                  this trip in My Trips.
                </div>
              </div>
            </span>
            <div
              className={styles.redemptionSuccessInfoFightDetails}
              id="receiptToPrint"
            >
              <div className={styles.flightDetails}>
                <div className={styles.flightDetailsWithContinueBtn}>
                  <div className={styles.flightDetailsWithTermsCondition}>
                    <div className={styles.flightDetailsWithPaymentView}>
                      <div className={styles.flightInfoWithMemberDetails}>
                        <div className={styles.memberDetailsDiv}>
                          <div className={styles.memberWithFlightLayout}>
                            <div className={styles.memberAndFlightName}>
                              <div className={styles.memberAndFlightNameBottom}>
                                <div className={styles.memberAndFlightNameDiv}>
                                  <div className={styles.bookingRef}>
                                    <span className={styles.memberNameLable}>
                                      Booking Ref
                                    </span>
                                    <span className={styles.memberNameLable}>
                                      {confirmationRes?.pnr}
                                    </span>
                                  </div>
                                  <div className={styles.memberName}>
                                    <span className={styles.memberNameLable}>
                                      {t("label_enrolment_consent_member_name")}
                                    </span>
                                    <div className={styles.flightNum_Details}>
                                      <div
                                        className={styles.flightNumDivBorder}
                                      >
                                        <span
                                          className={
                                            styles.flightNameColourContent
                                          }
                                        >
                                          {displayNameInfo()}{" "}
                                        </span>
                                        <span className={styles.flightNumber}>
                                          |{" "}
                                          {sessionStorage.getItem("memberId") ||
                                            "MH19126"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={styles.flightinfoCont}>
                              <div className={styles.flightInfoDiv}>
                                <div className={styles.flightInfoLabelDiv}>
                                  <span className={styles.flightInfoTextLabel}>
                                    {t("review_info_flight_information_label")}
                                  </span>
                                </div>

                                <div className={styles.DeptArrivalDiv}>
                                  {confirmationRes?.itineraryInfos?.map(
                                    (flightInfoResponse: any, index: any) => (
                                      <div className={styles.flightDetailsList}>
                                        <div className={styles.departAndClass}>
                                          <div
                                            className={
                                              styles.departArrivalAndLocation
                                            }
                                          >
                                            <span
                                              className={
                                                styles.departureORReturnLabel
                                              }
                                            >
                                              {renderFlightDetails(
                                                flightInfoResponse?.origin
                                              )?.triptype === "departure"
                                                ? "Departure"
                                                : "Return"}
                                            </span>
                                            <div
                                              className={styles.originAndDepart}
                                            >
                                              <span
                                                className={
                                                  styles.originAndDepartValues
                                                }
                                              >
                                                {
                                                  renderFlightDetails(
                                                    flightInfoResponse?.origin
                                                  )?.cityName
                                                }{" "}
                                                -{" "}
                                                {
                                                  renderFlightDetails(
                                                    flightInfoResponse?.destination
                                                  )?.cityName
                                                }
                                              </span>
                                            </div>
                                          </div>
                                          <div className={styles.classes}>
                                            <span className={styles.classLabel}>
                                              {t("label_class")}
                                            </span>
                                            <div
                                              className={styles.displayClasses}
                                            >
                                              <span
                                                className={
                                                  styles.displayClassValue
                                                }
                                              >
                                                {
                                                  flightInfoResponse?.bookingClassName
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            styles.flightDetailsCardBorder
                                          }
                                        >
                                          <div
                                            className={styles.flightDetailsCard}
                                          >
                                            <div
                                              className={
                                                styles.originDestignation
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.originDestignationDiv
                                                }
                                              >
                                                <div
                                                  className={styles.originView}
                                                >
                                                  <div
                                                    className={
                                                      styles.timeAndDate
                                                    }
                                                  >
                                                    {isDesktopView && (
                                                      <span
                                                        className={styles.time}
                                                      >
                                                        {moment(
                                                          flightInfoResponse?.departureTime,
                                                          "HHmm"
                                                        ).format("hh:mm")}
                                                      </span>
                                                    )}
                                                    <span
                                                      className={styles.date}
                                                    >
                                                      {moment(
                                                        formatDate(
                                                          flightInfoResponse?.departureDate
                                                        )
                                                      ).format(
                                                        "ddd, DD-MMM-YYYY"
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div
                                                    className={styles.location}
                                                  >
                                                    <div
                                                      className={
                                                        styles.originDestn
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          styles.originDestnCode
                                                        }
                                                      >
                                                        {
                                                          flightInfoResponse?.origin
                                                        }
                                                      </span>
                                                      <span
                                                        className={
                                                          styles.originDestnName
                                                        }
                                                      >
                                                        {isDesktopView
                                                          ? renderFlightDetails(
                                                              flightInfoResponse?.origin
                                                            )?.cityName
                                                          : flightInfoResponse?.departureTime}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.airportLocation
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles.airportLocationName
                                                      }
                                                    >
                                                      {
                                                        renderFlightDetails(
                                                          flightInfoResponse?.origin
                                                        )?.airportName
                                                      }
                                                    </span>
                                                    <span
                                                      className={
                                                        styles.terminal
                                                      }
                                                    >
                                                      {flightInfoResponse?.departureTerminal &&
                                                        `Terminal ${flightInfoResponse?.departureTerminal}}`}
                                                    </span>
                                                  </div>
                                                </div>

                                                <div
                                                  className={
                                                    styles.hoursAndstops
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles.displayHours
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles.displayHoursValue
                                                      }
                                                    >
                                                      {moment
                                                        .utc(
                                                          moment(
                                                            flightInfoResponse?.arrivalTime,
                                                            "HHmm"
                                                          ).diff(
                                                            moment(
                                                              flightInfoResponse?.departureTime,
                                                              "HHmm"
                                                            )
                                                          )
                                                        )
                                                        .format("HH:mm")}
                                                    </span>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.flightIconDiv
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.flightIconHypen
                                                      }
                                                    ></div>
                                                    <div
                                                      className={
                                                        styles.flightIcon
                                                      }
                                                    >
                                                      <TGIcon
                                                        icon="review-info-fight-icon"
                                                        fillColor=""
                                                        size="10px"
                                                      />
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.flightIconHypen
                                                      }
                                                    ></div>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.stopORNonStop
                                                    }
                                                  >
                                                    {flightInfoResponse?.numOfStops !==
                                                    "0" ? (
                                                      <span
                                                        className={
                                                          styles.stopORNonStopLabel
                                                        }
                                                      >
                                                        {
                                                          flightInfoResponse?.numOfStops
                                                        }{" "}
                                                        {t(
                                                          "label_redemption_stop"
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className={
                                                          styles.stopORNonStopLabel
                                                        }
                                                      >
                                                        {t("label_nonstop")}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>

                                                <div
                                                  className={styles.destinView}
                                                >
                                                  <div
                                                    className={
                                                      styles.timeAndDate
                                                    }
                                                  >
                                                    {isDesktopView && (
                                                      <span
                                                        className={styles.time}
                                                      >
                                                        {moment(
                                                          flightInfoResponse?.arrivalTime,
                                                          "HHmm"
                                                        ).format("hh:mm")}
                                                      </span>
                                                    )}
                                                    <span
                                                      className={styles.date}
                                                    >
                                                      {moment(
                                                        formatDate(
                                                          flightInfoResponse?.arrivalDate
                                                        )
                                                      ).format(
                                                        "ddd, DD-MMM-YYYY"
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div
                                                    className={styles.location}
                                                  >
                                                    <div
                                                      className={
                                                        styles.originDestn
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          styles.originDestnCode
                                                        }
                                                      >
                                                        {
                                                          flightInfoResponse?.destination
                                                        }
                                                      </span>
                                                      <span
                                                        className={
                                                          styles.originDestnName
                                                        }
                                                      >
                                                        {isDesktopView
                                                          ? renderFlightDetails(
                                                              flightInfoResponse?.destination
                                                            )?.cityName
                                                          : flightInfoResponse?.arrivalTime}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.airportLocation
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles.airportLocationName
                                                      }
                                                    >
                                                      {
                                                        renderFlightDetails(
                                                          flightInfoResponse?.destination
                                                        )?.airportName
                                                      }
                                                    </span>
                                                    <span
                                                      className={
                                                        styles.terminal
                                                      }
                                                    >
                                                      {flightInfoResponse?.arrivalTerminal &&
                                                        ` Terminal ${flightInfoResponse?.arrivalTerminal}`}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {isDesktopView && (
                                              <div className={styles.logoView}>
                                                <div
                                                  className={
                                                    styles.thaiLogoAndFlightNum
                                                  }
                                                >
                                                  <div
                                                    className={styles.thaiLogo}
                                                  >
                                                    <TGIcon
                                                      icon="review-info-thai-logo"
                                                      fillColor=""
                                                      size="20px"
                                                    />
                                                    <TGIcon
                                                      icon="review-info-thai-logo-text"
                                                      fillColor=""
                                                      size="40px"
                                                    />
                                                  </div>
                                                  <span
                                                    className={styles.flightNum}
                                                  >
                                                    {flightInfoResponse?.mc}{" "}
                                                    {
                                                      flightInfoResponse?.flightNumber
                                                    }
                                                  </span>
                                                </div>
                                                <span
                                                  className={
                                                    styles.flightNumAndCode
                                                  }
                                                >
                                                  Airbus A350-800
                                                </span>
                                              </div>
                                            )}
                                            <div></div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={styles.passengersLayout}>
                            {/* Passengers */}
                            <div className={styles.passengersDiv}>
                              <div className={styles.passengersViewAlign}>
                                <div className={styles.passengersBox}>
                                  <div className={styles.passengersLabelDiv}>
                                    <span className={styles.passengersLabel}>
                                      {t("label_book_widget_passengers")}
                                    </span>
                                  </div>
                                </div>
                                {confirmationRes?.fareInfo?.map(
                                  (fareInfoDetails: any, index: any) => (
                                    <div className={styles.contactInfoCard}>
                                      <div className={styles.fareDetailsView}>
                                        <div
                                          className={styles.fareDetailsLayout}
                                        >
                                          <div
                                            className={
                                              styles.displayFareDetailsCrad
                                            }
                                          >
                                            <div
                                              className={
                                                styles.displayFareDetails
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.fareMemberDetailsView
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles.passengerTextColourAndMember
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      styles.passengerColourTextLabel
                                                    }
                                                  >
                                                    {fareInfoDetails?.firstName &&
                                                      camelCase(
                                                        fareInfoDetails?.firstName
                                                      )}{" "}
                                                    {fareInfoDetails?.lastName &&
                                                      camelCase(
                                                        fareInfoDetails?.lastName
                                                      )}
                                                  </span>
                                                  {index === 0 && (
                                                    <span
                                                      className={
                                                        styles.memberLabel
                                                      }
                                                    >
                                                      {review_info_member}
                                                    </span>
                                                  )}
                                                </div>
                                                <div
                                                  className={
                                                    styles.fareAndMiles
                                                  }
                                                >
                                                  <span
                                                    className={styles.fareLabel}
                                                  >
                                                    {t(
                                                      "label_redemption_summary_fare"
                                                    )}
                                                  </span>
                                                  <span
                                                    className={styles.milesData}
                                                  >
                                                    {
                                                      fareInfoDetails?.totalMiles
                                                    }{" "}
                                                    {t(
                                                      "label_redemption_header_miles"
                                                    )}
                                                  </span>
                                                </div>
                                                <div
                                                  className={
                                                    styles.taxesAndSurchages
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      styles.taxesAndSurchagesLabel
                                                    }
                                                  >
                                                    {t(
                                                      "label_redemption_summary_taxes_and_surchanges"
                                                    )}
                                                  </span>
                                                  <span
                                                    className={
                                                      styles.taxesAndSurchagesData
                                                    }
                                                  >
                                                    {fareInfoDetails?.currency}{" "}
                                                    {
                                                      fareInfoDetails?.totalFares
                                                    }
                                                  </span>
                                                </div>
                                                <div
                                                  className={
                                                    styles.taxesAndSurchages
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      styles.baggageTitle
                                                    }
                                                  >
                                                    Baggage
                                                  </span>
                                                  <span
                                                    className={
                                                      styles.taxesAndSurchagesData
                                                    }
                                                  ></span>
                                                </div>
                                                {fareInfoDetails?.baggage?.map(
                                                  (x: any) => (
                                                    <div
                                                      className={
                                                        styles.taxesAndSurchages
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          styles.baggageAirportTitle
                                                        }
                                                      >
                                                        {x?.origin}-
                                                        {x?.destination}
                                                      </span>
                                                      <span
                                                        className={
                                                          styles.baggageAirportTitle
                                                        }
                                                      >
                                                        {x?.allowance}
                                                      </span>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              {/* contact information */}
                              <div className={styles.contactInfoDiv}>
                                <div className={styles.contactInforLabelDiv}>
                                  <span className={styles.contactInfoLabel}>
                                    {t("review_info_contact_info_label")}
                                  </span>
                                </div>
                                <div className={styles.phoneNumAndEmail}>
                                  <div className={styles.phoneNum}>
                                    <span className={styles.phoneNumLabel}>
                                      {t("profile_view_contact")}
                                    </span>
                                    <div className={styles.phoneNumValueCode}>
                                      <span className={styles.phoneNumValue}>
                                        {confirmationRes?.phoneNum?.replace(
                                          "00-",
                                          ""
                                        ) || ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles.emailID}>
                                    <span className={styles.emailLabel}>
                                      {t("label_reset_email")}
                                    </span>
                                    <span className={styles.emailIdValue}>
                                      {confirmationRes?.email
                                        ? camelCase(confirmationRes?.email)
                                        : "olivia@gmail.com"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.paymentMethodDiv}>
                        {/* Acceptance payments method */}
                        <div className={styles.paymentAndItinerary}>
                          <div className={styles.itinaryPricingDiv}>
                            <span className={styles.itinaryPricingLabel}>
                              {t("review_info_itineraty_pricing_label")}
                            </span>
                            <div className={styles.totalmilesDiv}>
                              <div className={styles.totalmilesAndTaxes}>
                                <div className={styles.totalmilesAndFares}>
                                  <div className={styles.totalmilesAndFaresDiv}>
                                    <span className={styles.totalMilesLabel}>
                                      {t("review_info_total_miles_used_label")}
                                    </span>
                                    <span className={styles.totalMilesValues}>
                                      {confirmationRes?.totalMiles || ""}
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      styles.applicableTaxesAndSurchages
                                    }
                                  >
                                    <span
                                      className={
                                        styles.applicableTaxesAndSurchagesLabel
                                      }
                                    >
                                      {t(
                                        "review_info_taxes_and_surcharges_label"
                                      )}
                                    </span>
                                    <span
                                      className={
                                        styles.applicableTaxesAndSurchagesData
                                      }
                                    >
                                      {confirmationRes?.currency || ""}{" "}
                                      {confirmationRes?.totalFare || ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <span className={styles.ticketRestrictionLabel}>
                                {t("review_info_ticket_restriction_label")}:
                                {ticketRestrictionData(ticketRestriction)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.termsAndConditionDiv}>
                      <div className={styles.horizontalVectorIcon}>
                        <div className={styles.verticalLineTerms}></div>
                        <div className={styles.termsAndConditionBorder}></div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.cancelBtnDiv}>
                    <div className={styles.cancelAndProceedBtnDiv}>
                      <div className={styles.cancelButtonDiv}>
                        <button className={styles.cancelButton}>
                          <span className={styles.cancelLabel}>
                            Back to Home
                          </span>
                        </button>
                      </div>
                      {isDesktopView && (
                        <div className={styles.proceedToPaymentBtnDiv}>
                          <button className={styles.proceedToPaymentBtn}>
                            <span
                              className={styles.proceedToPaymentLabelDesktop}
                              onClick={printSuccessReceipt}
                            >
                              {isDesktopView
                                ? "Save as PDF"
                                : "Save Itinerary as PDF"}
                            </span>
                          </button>
                        </div>
                      )}
                      <div className={styles.proceedToPaymentBtnDiv}>
                        <button className={styles.proceedToPaymentBtn}>
                          <span
                            className={styles.proceedToPaymentLabelDesktop}
                            onClick={printSuccessReceipt}
                          >
                            {isDesktopView ? "Print" : "Save Itinerary as PDF"}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    !summaryRes?.isLoading && (
      <TGFullScreenError
        heading={t("error_payment_failed_heading")}
        message={t("error_payment_failed_desc")}
        buttonPrimaryText={t("label_try_again")}
        buttonSecText={t("button_generic_error_text")}
        onClickPrimary={handleRetry}
        onClickSec={() => {}}
      />
    )
  );
};

export default RedemptionSuccess;
