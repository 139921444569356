import React from "react";
import { ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import styles from "./redemption.module.scss";
import SelectFlight from "./select-flight";
import ReviewPassenger from "./review-passenger";
import ReviewInformation from "./review-information";
import TGIcon from "../../../../shared/tg-icon";
import { useNavigate } from "react-router-dom";
import { resetPersistStore } from "../../../../store";

const Redemption: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  let isMobile = useScreenSize()?.deviceSize?.width < 768;
  const pathName = window?.location?.pathname?.split("/")?.[3];
  const tabsData = [
    {
      name: "Select Flight",
      value: 0,
      component: <SelectFlight />,
      disabled: pathName !== "selectflight",
    },
    {
      name: "Review Passengers",
      value: 1,
      component: <ReviewPassenger />,
      disabled: pathName !== "passengers",
    },
    {
      name: "Review Information",
      value: 2,
      component: <ReviewInformation />,
      disabled: pathName !== "review",
    },
    {
      name: "Confirm Payment",
      value: 3,
      component: <>Confirm Payment</>,
      disabled: true,
    },
  ];

  const activeTab = tabsData?.filter((x: any) => !x?.disabled)?.[0];

  const handleBack = () => {
    resetPersistStore();
    navigate("/");
  };

  return (
    <>
      <div className={`${styles.bookingContainer} global-content-padding`}>
        {isMobile && (
          <div className={styles.backarrowIcon} onClick={handleBack}>
            <TGIcon icon="back-arrow-btn-redemption" fillColor="" size="24px" />
          </div>
        )}
        {!isDesktopView && (
          <div className={styles["redemption-mobile-active-tab"]}>
            <div>{activeTab?.name}</div>
            <div>{`${activeTab?.value + 1} / ${tabsData?.length}`}</div>
          </div>
        )}
        {
          <div className={`${styles["redemption-tab-container"]}`}>
            {tabsData.map((radio, idx) => {
              return isDesktopView ? (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant={""}
                  name="radio"
                  value={radio.value}
                  className={
                    !radio?.disabled
                      ? styles["redemption-active-tab-container"]
                      : styles["redemption--inactive-tab-container"]
                  }
                  checked={!radio?.disabled}
                  disabled={radio?.disabled}
                >
                  <div
                    className={`${styles["redemption-tabs"]} ${
                      !radio?.disabled && styles["redemption-active-tabs"]
                    }`}
                  >
                    {idx + 1 + "  " + radio.name}
                  </div>
                </ToggleButton>
              ) : (
                <div
                  className={`${styles["redemption-tab-mobile--container"]} ${
                    !radio?.disabled &&
                    styles["redemption-active-tab-mobile--container"]
                  }`}
                />
              );
            })}
          </div>
        }
      </div>
    </>
  );
};

export default Redemption;
