import React, { useState, createContext } from "react";
import TGIcon from "../../../../../shared/tg-icon";
import styles from "./redemption-header.module.scss";
import style from "./../../navigation/navigation.module.scss";
import { default as separator } from "../../../../../../public/assets/RedemptionHeaderSeparator.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import MiniProfile from "../../../../../shared/mini-profile";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import { resetPersistStore } from "../../../../../store";
import {
  clearSelectedFlightInfoData,
  clearSelectedFlightIndex,
  resetRedemeptionBookingData,
} from "../../../slice/flightInfoSlice";

export const FunctionContextRedemption = createContext(() => {});

const RedemptionHeader: React.FC = () => {
  const dispatch = useDispatch();
  const profileDetails = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );
  const selectecFlightDetails = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.redemptionBookingData
  );
  const [showMiniProfile, setShowMiniProfile] = useState(false);
  const { i18n } = useTranslation();
  const isDesktopView = useScreenSize()?.deviceSize?.width > 767;
  // When profile details is not there.It is an unauthorized use, so redirect to popup.
  // if (
  //   Object.keys(profileDetails).length === 0 ||
  //   !selectecFlightDetails?.length
  // ) {
  //   window.location.href = `/${i18n.language}` + "/?query=signin";
  // }

  const onClickHeaderLogo = () => {
    dispatch(clearSelectedFlightInfoData());
    dispatch(clearSelectedFlightIndex());
    dispatch(resetRedemeptionBookingData());
    resetPersistStore();
    window.location.replace(window.location.origin);
  };
  return (
    <div className={`${styles.headerContainer} global-content-padding`}>
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <div
            className={styles.logoContainer}
            onClick={() => onClickHeaderLogo()}
          >
            <div className={styles.logo}>
              <TGIcon icon="thai-header-logo" fillColor="" size="" />
            </div>
            <TGIcon icon="thai-header-text" fillColor="" size="" />
          </div>
          <div className={styles.profileContainer}>
            <div className={styles.profile}>
              {isDesktopView && (
                <div className={styles.memberDetails}>
                  <span className={styles.tierInfo}>
                    {profileDetails.privilegeCard}
                  </span>
                  <span className={styles.memberId}>
                    {profileDetails.memberID}
                  </span>
                  <div className={styles.separator}>
                    <img alt="separator" src={separator} />
                  </div>
                  <span className={styles.miles}>
                    {profileDetails.remainingMiles}{" "}
                    {t("label_redemption_header_miles")}
                  </span>
                </div>
              )}
              {isDesktopView ? (
                <div
                  className={styles.memberName}
                  onClick={() => {
                    setShowMiniProfile(!showMiniProfile);
                  }}
                >
                  <TGIcon icon="sign-in-icon" fillColor="" size="16px" />
                  <div className={styles.member}>
                    <span className={styles.nameText}>
                      {profileDetails.firstName}
                    </span>
                    <TGIcon icon="chevron-down-icon" fillColor="" size="16px" />
                  </div>
                </div>
              ) : (
                <div
                  className={style.myProfile}
                  onClick={() => {
                    setShowMiniProfile(!showMiniProfile);
                  }}
                >
                  <span className={style.myProfileLable}>
                    {t("label_myprofile")}
                  </span>
                  <TGIcon
                    icon="my-profile-down-arrow-icon"
                    size={"20px"}
                    fillColor={"none"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showMiniProfile && (
        <FunctionContextRedemption.Provider
          value={() => {
            setShowMiniProfile(false);
          }}
        >
          <MiniProfile />
        </FunctionContextRedemption.Provider>
      )}
    </div>
  );
};

export default RedemptionHeader;
